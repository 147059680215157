import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild, } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { ConfirmationService, MessageService } from 'primeng/api';
import { events } from '../../domain/events';
import { User } from '../../domain/user';
import { eventservice } from '../../service/eventservice';
import { Observable, of } from 'rxjs';
import { scheduleeventservice } from '../../service/scheduleeventservice';
import { UserDetailsService } from '../../service/userDetails.service';
import { scheduleevent } from '../../domain/scheduleevent';
import { Visit } from '../../domain/visit';
import { VisitService } from '../../service/visitservice';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ServiceProviderService } from '../../service/serviceprovider.service';
import { serviceprovider } from '../../domain/serviceprovider';
import { ServiceDetailsService } from '../../service/serviceDetails.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HomeComponent implements OnInit  {
 
  startdate: Date;
  enddate: Date;
  todaydate: Date;
  mindate: Date;
  maxdate: Date;
  events: any[];
  userId: any;
  loginuserId: any;
  loginusername: any;
  selectedVisit: scheduleevent;
  selectedVisitdata: Visit;
  visitdatesisSubmitted = false;
  visitdatesModal = false;
  eventdisplayModal = false;
  travelEventdisplayModal = false;
  visitdisplayModal = false;
  eventnoeditModal = false;
  selectedEvent: scheduleevent;
  selectedvisitevent: Visit;
  options: any;
  visits: Visit[];
  days: any[];

  selecteduserdetails: User;
  scheduleevents: events[];
  scheduleeventstemp: events[];
  futureassignments: scheduleevent[];
  futureassignmentstemp: scheduleevent[];
  scheduleserviceeventtemp: scheduleevent[];
  defaultConfigurations: any;
  isSubmitted = false;
  sse: scheduleevent;
  selectedUserId: any;
  uploading: boolean;
  loginuserrole: string;
  isDisabled: boolean;
  description: any;
  currentPeriod: any = {};
  currentperiodStartDate: Date;
  currentperiodminDate: Date;
  currentPeriodmaxdate: Date;
 // invalidDates: Date = new Date();
  progressbar: boolean;
  officerTwomsg: String;
  currentperiodLeaveCutoff: Date;
  leaveCutOffDate: any;
  updateCalenderModal:boolean;
  vacationCareOnly: boolean;
  largeproviderslist: serviceprovider[];
  serviceslist:any;
  constructor(public datepipe: DatePipe, private eventService: eventservice, private servicedetails: ServiceDetailsService ,
    private confirmationService: ConfirmationService,
    private serviceproviderservice: ServiceProviderService, public fb: FormBuilder, private visitservice: VisitService, private UserDetailsService: UserDetailsService, private eventservice: eventservice, private messageService: MessageService, private scheduleeventservice: scheduleeventservice) {

      this.days = [
        {name: 'Mon', value: '1'},
        {name: 'Tue', value: '2'},
        {name: 'Wed', value: '3'},
        {name: 'Thu', value: '4'},
        {name: 'Fri', value: '5'},
    
      ];

  }

  updatevisitdatesform = this.fb.group({
    fvstartdate: ['', [Validators.required]],
    fvenddate: ['', [Validators.required]],
  });
  addeventform = this.fb.group({
    feventtype: ['', [Validators.required]],
    fstartdate: ['', [Validators.required]],
    fenddate: ['', [Validators.required]],
    fdaysofweek: ['', []],
    fnotes: ['',]
  });
  

  dateLessThan(fstartdate: Date, fenddate: Date) {
    let startdate = new Date(fstartdate.getFullYear(), fstartdate.getMonth(), fstartdate.getDate(), 0, 0, 0);
    let enddate = new Date(fenddate.getFullYear(), fenddate.getMonth(), fenddate.getDate(), 0, 0, 0);
    if (enddate.getTime() < startdate.getTime()) {
      return true;
    }
    return false;
  }
 

  minDateValue:Date;
  maxDateValue:Date;
  parttimeflag:string="false";
  ngOnInit() {
    this.minDateValue = new Date();
    this.progressbar = true;
    this.conflictmessage="";
    this.minDateValue.setMonth( this.minDateValue.getMonth() - 6);

    this.maxDateValue=new Date();
    this.maxDateValue.setFullYear(this.maxDateValue.getFullYear() + 2)

    this.uploading = true;
    this.todaydate = new Date();

    this.loginuserId = localStorage.getItem("loginuserid");
    this.loginusername = localStorage.getItem("username");
    this.loginuserrole = localStorage.getItem("loginuserrole");


    this.selectedUserId = localStorage.getItem("USERID");

    if (this.selectedUserId == null) {
      this.userId = localStorage.getItem("loginuserid")
    } else {
      this.userId = localStorage.getItem("USERID")
    }
    this.getCurrentPeriod();
    this.UserDetailsService.getUserforId(this.userId).subscribe(userdata => {
      this.selecteduserdetails = userdata;
      this.parttimeflag=this.selecteduserdetails.parttimeflag;
      console.log(userdata);
      
    });
    this.serviceproviderservice.getAllSPDetails().subscribe(data=>{
      this.largeproviderslist = data.filter(el=>el.largeProviderName!=null);
     
    this.servicedetails.getAllServices().subscribe(sd=>{
      this.serviceslist = sd;

      console.log(this.serviceslist.length)
      let servicelist = this.serviceslist.filter(x => 
        this.largeproviderslist.some(y=> y.serviceProviderId == x.serviceProviderId)
      );

    // get future assigments userId
    this.scheduleeventservice.getUserEventsByIdFirstOrSecond(this.userId).subscribe(ssedata => {
      if (ssedata != null) {
        this.futureassignmentstemp = ssedata;
        this.futureassignments = [];
        this.futureassignmentstemp.forEach(eledata => {
          if (eledata.eventType == 'V') {
            eledata.eventType = "AR"
          }

          let datafound = servicelist.filter(e=>e.serviceId===eledata.serviceId);

          //if its a large provider serviceId
          if(datafound && datafound.length>0)
          {
            eledata.markit=true;
            let serviceProviderName = this.largeproviderslist.find(p=>p.serviceProviderId==datafound[0].serviceProviderId);
            eledata.largeProviderName = serviceProviderName.largeProviderName;
          }
          if (eledata.futureVisit == 'N' && eledata.startDate == null && eledata.archived==null) {
            this.futureassignments.push(eledata);
          }
        });
        this.progressbar = false;

      }else{
        this.progressbar = false;

      }
    });

  })
})
    this.scheduleeventservice.getUserCalendarById(this.userId).subscribe(ssedata => {
      if (ssedata != null) {
        this.scheduleserviceeventtemp = ssedata;
        let d = new Date();
        d.setMonth(d.getMonth() - 6);
        

      //  console.log("this.userevents size before"+ this.scheduleserviceeventtemp.length + "date ="+d.getMonth())   
    
        this.scheduleserviceeventtemp = this.scheduleserviceeventtemp.filter(el=>((new Date(el.endDate)).getTime()>=d.getTime()))        
      //  console.log("this.userevents size"+ this.scheduleserviceeventtemp.length)                          

        this.events = [];
        this.scheduleserviceeventtemp.forEach(eledata => {
          var bgcolor = this.getbgcolor(eledata.eventType);
          //var re = /00:00:00/;
          //var startdate = new Date(""+eledata.startDate);
          //var stDate = new Date(startdate.toString().replace(re, "00:00:01"));
         
          var enddate = new Date(""+eledata.endDate);
          var enddaterecur = new Date(""+eledata.endDateRecur);
        //  enddate.setDate(enddate.getDate()+1);
       //   var eDate = new Date(enddate.toString().replace(re, "00:00:00"));
       var eDate = new Date(enddate.getFullYear(), enddate.getMonth(), enddate.getDate()+1, 0, 0, 0);
          
       
      //   this.events.push({ id: eledata.eventId, source: eledata, title: eledata.title, start: eledata.startDate, end: eDate, textColor: 'black', editable: false, backgroundColor: bgcolor, allDay: true });
       //   this.events.push({ id: eledata.eventId, source: eledata, title: eledata.title, start: eledata.startDate, end: this.addDays(eledata.endDate), textColor: 'black', editable: true, backgroundColor: bgcolor, allDay: true });
       var eDateRecur =  new Date(enddaterecur.getFullYear(), enddaterecur.getMonth(), enddaterecur.getDate()+1, 0, 0, 0);
       var arr = null;

       if(this.selecteduserdetails!=undefined && this.selecteduserdetails!=null && eledata.secondUserId=== this.selecteduserdetails.userId && eledata.eventType=='V')
       {
        bgcolor ='#c8f8c8'
       }
 
       if(eledata.daysofweek!=null)
       {
       arr = eledata.daysofweek.split(',');
       }
         this.events.push({id:eledata.eventId,source:eledata,title:eledata.title,start:eledata.startDate,end:eDate,textColor:'black',editable: false,backgroundColor:bgcolor,allDay:true,daysOfWeek:arr,startRecur:eledata.startDateRecur,endRecur:eDateRecur});    
          
      });
      }
    });
    this.options = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      defaultDate: new Date,
      height: 800,
      header: {
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      },
      editable: true,
      validRange: {
        start: this.minDateValue,
        end: this.maxDateValue,
      },
      eventClick: (event) => {
        this.description = null;
        this.startdate = null;
        this.enddate = null;
        this.vacationCareOnly=false;
        this.scheduleeventservice.get(event.event.id).subscribe(sedata => {
          this.selectedEvent = sedata;

         // this.getPeriodByPerName(sedata.periodId);
          if (this.selectedEvent.eventType == "L" || this.selectedEvent.eventType == "F" || this.selectedEvent.eventType == "LP" || this.selectedEvent.eventType == "H") {
            this.getleavedata(event.event.id, this.selectedEvent.eventType);
          } else if (this.selectedEvent.eventType == "V" || this.selectedEvent.eventType == "COM" || this.selectedEvent.eventType == "CofO" || this.selectedEvent.eventType == "I") {
            console.log(this.selectedUserId);
            console.log(sedata);
            
            if (this.selecteduserdetails.userId != sedata.userId) {
              this.officerTwomsg = "as Officer 2, please speak to your Hub Coordinator.";
              this.getnoedit();
            } else {
              this.getPeriodByPerName(sedata.periodId);

              this.getvisitdata(event.event.id);
            }
          } else {
            this.getnoedit();
          }
        });
      },
    };
  }

  

  getCurrentPeriod() {
    this.visitservice.getCurrentPeriod().subscribe(data => {
      if(data!=undefined && data!=null)
      {
        this.currentperiodLeaveCutoff = new Date(data.leaveCutoffDate);
      }
    
    });
  }
  isSecondOfficer(secondUserId: string)
  {
    if(this.selecteduserdetails!=undefined && this.selecteduserdetails!=null && secondUserId=== this.selecteduserdetails.userId)
    {

      return true;
    }
    else{
      return false;
    }
  }
  getPeriodByPerName(id: any) {
    this.currentPeriod = {};
    this.currentperiodminDate = null;
    this.currentperiodStartDate=null;
    this.currentPeriodmaxdate = null;
    this.visitservice.getPeriodsById(id).subscribe(data => {
      this.currentPeriod = data;
      if(this.currentPeriod.perStartDate)
      {
        this.currentperiodStartDate=new Date(this.currentPeriod.perStartDate);
      }
     
      if (new Date(this.currentPeriod.perStartDate) > new Date()) {
        this.currentperiodminDate = new Date(this.currentPeriod.perStartDate);
      } else {
        this.currentperiodminDate = new Date();
      }
      this.currentPeriodmaxdate = new Date(this.currentPeriod.perEndDate);
    }, err => {
    //  this.invalidDates = new Date(this.invalidDates.setDate(new Date().getDate() - 1));

    })
  }

  getnoedit() {
    this.eventnoeditModal = true;
  }
  getleavedata(event: String, type: string) {
    if (type == "H" || type == "F") {
      this.gettraveldata(event);
      return false;
    }
    this.messageService.clear();
    //get the event data and type

    this.scheduleeventservice.get(event).subscribe(sedata => {
      this.selectedEvent = sedata;
      this.startdate = new Date(sedata.startDate);
      this.enddate = new Date(sedata.endDate);
      if (this.startdate < new Date()) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
      this.eventdisplayModal = true;
    });

  }

  gettraveldata(event: String) {
    this.messageService.clear();
    //get the event data and type
    this.travelEventdisplayModal = true;
    this.scheduleeventservice.get(event).subscribe(sedata => {
      this.selectedEvent = sedata;
      this.startdate = new Date(sedata.startDate);
      this.enddate = new Date(sedata.endDate);
    });
  }

  getvisitdata(event: String) {
    this.messageService.clear();
    //get the event data and type
    this.conflictmessage="";

    this.visitdisplayModal = true;
    this.visitservice.getEventsById(event).subscribe(sedata => {
      this.selectedvisitevent = sedata;
      if(!this.selectedvisitevent.isLongDayCare && !this.selectedvisitevent.outHoursCareAfter && !this.selectedvisitevent.outHoursCareBefore && this.selectedvisitevent.outHoursVacationCare){
        this.vacationCareOnly=true;
      }
      this.startdate = new Date(sedata.startDate);
      this.enddate = new Date(sedata.endDate);
    });
  }

  removeevent() {
    this.messageService.clear();
    this.scheduleeventservice.delete(this.selectedEvent.eventId).subscribe(res => {
      this.messageService.add({ severity: 'success', summary: 'success', detail: 'Event removed.' });
      this.ngOnInit();
    }, err => {
      this.messageService.add({ severity: 'error', summary: 'fail', detail: 'Event remove failed.' });
    });
    this.eventdisplayModal = false;
    this.travelEventdisplayModal = false;
  }

  checkLeaveDatewithdate(editRemove:string){
    var re = /00:00:00/;
    var startdate = this.startdate
    var stDate = startdate.toString().replace(re, "00:00:01");
    var enddate = this.enddate
    var eDate = enddate.toString().replace(re, "23:59:00");
    this.scheduleeventservice.overlappedFlexPeriod(stDate, eDate).subscribe(res => {
      if(!res){
        if(editRemove=='edit'){
          this.editeventdates();
        }else if(editRemove=='remove'){
          this.removeevent();
        }
        return false;
      }
      if (this.todaydate > new Date(res.leaveCutoffDate)) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Leave cut-off date was' + this.datepipe.transform(res.leaveCutoffDate, 'dd/MM/yyyy') + ',please contact your Hub Coordinator.' });
        this.eventdisplayModal = false;
        this.travelEventdisplayModal = false;
        return;
      }else{
        if(editRemove=='edit'){
          this.editeventdates();
        }else if(editRemove=='remove'){
          this.removeevent();
        }
      }
    })
  }

  editeventdates() {
      let flag = true;
       //check for clashing events
       console.log("check for clashing events"+this.selectedUserId);

       console.log("editeventdates in.. "+this.selectedEvent.eventId+this.selectedEvent.eventType);

       let userIdtocheck = this.selectedUserId;
       if( userIdtocheck == null || userIdtocheck ==undefined)
       {
        userIdtocheck = this.userId;
       }
       this.scheduleeventservice.getUserEventsById(userIdtocheck).subscribe(res=>{
 
         let mypromise = new Promise((resolve,reject) => {
           let startDate = environment.datePipe.transform(new Date(this.startdate), 'yyyy-MM-dd');
           let endDate = environment.datePipe.transform(new Date(this.enddate), 'yyyy-MM-dd');
           //console.log("startDate and enddate"+startDate+endDate);
           //console.log("res"+res)

           if(res==null || res==undefined || res.length==0)
           {
             resolve(null);
 
           }
        
           let filterednonrecurring = res.filter(element=>( (this.selectedEvent.eventId !== element.eventId)&&(element.eventType!='MAT' && element.eventType!='O' && element.eventType!='LP' && element.eventType!='LWOP')&&(((startDate>=environment.datePipe.transform(element.startDate, 'yyyy-MM-dd') && startDate<=environment.datePipe.transform(element.endDate, 'yyyy-MM-dd')) || (endDate>=environment.datePipe.transform(element.startDate, 'yyyy-MM-dd') && endDate<=environment.datePipe.transform(element.endDate, 'yyyy-MM-dd')) || (startDate<environment.datePipe.transform(element.startDate, 'yyyy-MM-dd') && endDate>environment.datePipe.transform(element.endDate, 'yyyy-MM-dd'))))   ))
           //console.log("filterednonrecurring..."+filterednonrecurring.length)
              if(filterednonrecurring!=null && filterednonrecurring.length>0)
              {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Clashing event' });
                this.addeventform.reset();
                this.isSubmitted=false;
  
  
                flag= false;
                resolve(null);
              }
            
  
                 else{ // no clashes
                  resolve(null);
  
                }
       
       })
       mypromise.then(() => {
         if(flag)
         {
    this.messageService.clear();
    var re = /00:00:00/;
      var startdate = this.startdate
      var stDate = startdate.toString().replace(re, "00:00:01");
      var enddate = this.enddate
      var eDate = enddate.toString().replace(re, "23:59:00");
      if (this.dateLessThan(new Date(this.startdate), new Date(this.enddate))) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End date should be greater than Start date' });
      return;
    } else {
      this.scheduleeventservice.get(this.selectedEvent.eventId).subscribe(sedata => {
        this.sse = sedata;
        var description = this.description;
        this.sse.startDate = environment.datePipe.transform(new Date(startdate), 'yyyy-MM-dd');// new Date(stDate);
        this.sse.endDate = environment.datePipe.transform(new Date(enddate), 'yyyy-MM-dd');
        this.sse.description = description;

        this.sse.updatedBy = this.loginusername;
        this.sse.updatedDate = new Date();
        this.scheduleeventservice.updateEventsOnSchedule(this.sse).subscribe(res => {
          this.messageService.add({ severity: 'success', summary: 'success', detail: 'Event Dates updated.' });
          this.ngOnInit();
        }, err => {
          this.messageService.add({ severity: 'error', summary: 'success', detail: 'Update failed for Event Dates.' });
        });
        this.eventdisplayModal = false;
        this.travelEventdisplayModal = false;
      });
    }
  }
   })
  })
  }

  editvisitdates() {
    this.messageService.clear();
    if (this.dateLessThan(new Date(this.startdate), new Date(this.enddate))) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End date should be greater than Start date' });
      return;
    } else {
      this.updateCalenderModal=true;
      this.scheduleeventservice.get(this.selectedvisitevent.eventId).subscribe(sedata => {
        this.sse = sedata;
        var re = /00:00:00/;
        var startdate = this.startdate
        var stDate = startdate.toString().replace(re, "12:00:00");
        var enddate = this.enddate
        var eDate = enddate.toString().replace(re, "23:59:00");

        this.sse.startDate = new Date(stDate);
        this.sse.endDate = new Date(eDate);
        this.sse.updatedBy = this.loginusername;
        this.sse.updatedDate = new Date();
        const data = {
          startDate: environment.datePipe.transform(new Date(startdate), 'yyyy-MM-dd'),
          endDate: environment.datePipe.transform(new Date(enddate), 'yyyy-MM-dd'),
          updatedBy: this.loginusername,
          updatedDate: new Date(),
        }

        this.scheduleeventservice.updateserviceeventswithvdate(this.selectedvisitevent.eventId, data).subscribe(res => {
          this.visitdisplayModal = false;
          this.updateCalenderModal=false;
          this.messageService.add({ severity: 'success', summary: 'success', detail: 'Visit dates updated for ' + this.selectedvisitevent.serviceId });
          this.ngOnInit();
        }, err => {
          this.visitdisplayModal = false;
          this.updateCalenderModal=false;
          this.messageService.add({ severity: 'error', summary: 'success', detail: 'Visit dates updation failed for ' + this.selectedvisitevent.serviceId });
        });
       
      });
    }
  }
  addDays(date: Date): Date {
    let yourDate = new Date(date);
    yourDate.setDate(yourDate.getDate());
    return yourDate;
  }

  updateVisitDatesDialog(scheduleevent: scheduleevent) {
    this.messageService.clear();
    this.currentPeriod = {};
    this.currentperiodStartDate = null;
    this.currentperiodminDate = null;
    this.currentPeriodmaxdate = null;
    this.getPeriodByPerName(scheduleevent.periodId);
    this.vacationCareOnly=false;
    this.conflictmessage = "";

    this.visitdatesModal = true;
    //get the data to display service information.
    this.updatevisitdatesform = this.fb.group({
      fvstartdate: ['', [Validators.required]],
      fvenddate: ['', [Validators.required]],
    });
    this.visitservice.getEventsById(scheduleevent.eventId).subscribe(sedata => {
      this.selectedVisitdata = sedata;
      if(!sedata.isLongDayCare && !sedata.outHoursCareAfter && !sedata.outHoursCareBefore && sedata.outHoursVacationCare){
        this.vacationCareOnly=true;
      }      
      this.startdate = new Date(sedata.startDate);
      this.enddate = new Date(sedata.endDate);
      this.mindate = new Date(sedata.periodStartDate);
      this.maxdate = new Date(sedata.periodEndDate);
    });
  }

  conflictmessage = "";
  checkConflictDatesforLP()
  {
   let fstartdate = this.updatevisitdatesform.get("fvstartdate").value
   let fenddate = this.updatevisitdatesform.get("fvenddate").value
   this.conflictmessage = "";

   //console.log("periodName"+this.selectedVisitdata.periodName);

   if(fstartdate!=undefined && fstartdate!=undefined && fstartdate!="" && fenddate!=null && fenddate!=null && fenddate!="")
   {

   // let fstartdate =    new Date(startdate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
   fstartdate.setHours(0,0,0)


    fenddate.setHours(0,0,0)


   // console.log(this.selectedVisitdata.serviceProvId)
    let conflictservicelist = this.serviceslist.filter(x => 
      x.serviceProviderId == this.selectedVisitdata.serviceProvId)
  
    //console.log("conflictservicelist"+conflictservicelist.length)

    this.scheduleeventservice.getscheduleforperiodId(this.selectedVisitdata.periodName).subscribe(res=>{

      let filterdatascheduleevents= res.filter(x=>conflictservicelist.some(y=>y.serviceId==x.serviceId) && x.serviceId!=this.selectedVisitdata.serviceId && x.futureVisit=='N' && x.archived==null && x.startDate!=null && x.endDate!=null);
     // console.log("filterdatascheduleevents"+filterdatascheduleevents.length)

      
      filterdatascheduleevents.forEach(element => {
     
        let sstartdate =    new Date(element.startDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
        sstartdate.setHours(0,0,0)
        let senddate =    new Date(element.endDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
        senddate.setHours(0,0,0)
        const datepipe: DatePipe = new DatePipe('en-AU')
        console.log(sstartdate  + "-"+ senddate)

        console.log(fstartdate +" - "+ fenddate)

        if ((sstartdate>=fstartdate && sstartdate<=fenddate) ||(senddate<=fenddate && senddate>=fstartdate)) {    
          this.conflictmessage= this.conflictmessage + element.serviceId +" between "+datepipe.transform(sstartdate, 'dd/MM/YYYY') + " - " +datepipe.transform(senddate, 'dd/MM/YYYY') + '\n'
        }  
        
      });
    

    })
   }
  }
  checkConflictDatesforLPfromcal()
  {
   let fstartdate = this.startdate
   let fenddate = this.enddate;
   this.conflictmessage = "";

  // console.log("periodName"+this.selectedvisitevent.periodName);

   console.log("fstartdate"+fstartdate +  "   fenddate"+fenddate)

   if(fstartdate!=undefined && fstartdate!=undefined  && fenddate!=null && fenddate!=null )
   {

   // let fstartdate =    new Date(startdate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
   fstartdate.setHours(0,0,0)


    fenddate.setHours(0,0,0)


   // console.log(this.selectedvisitevent.serviceProvId)
    let conflictservicelist = this.serviceslist.filter(x => 
      x.serviceProviderId == this.selectedvisitevent.serviceProvId)
  
   // console.log("conflictservicelist"+conflictservicelist.length)

    this.scheduleeventservice.getscheduleforperiodId(this.selectedvisitevent.periodName).subscribe(res=>{

      let filterdatascheduleevents= res.filter(x=>conflictservicelist.some(y=>y.serviceId==x.serviceId) && x.serviceId!=this.selectedvisitevent.serviceId && x.futureVisit=='N' && x.archived==null && x.startDate!=null && x.endDate!=null);
    //  console.log("filterdatascheduleevents"+filterdatascheduleevents.length)

      
      filterdatascheduleevents.forEach(element => {
     
        let sstartdate =    new Date(element.startDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
        sstartdate.setHours(0,0,0)
        let senddate =    new Date(element.endDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
        senddate.setHours(0,0,0)
        const datepipe: DatePipe = new DatePipe('en-AU')
        console.log(sstartdate  + "-"+ senddate)

        console.log(fstartdate +" - "+ fenddate)

        if ((sstartdate>=fstartdate && sstartdate<=fenddate) ||(senddate<=fenddate && senddate>=fstartdate)) {    
          this.conflictmessage= this.conflictmessage + element.serviceId +" between "+datepipe.transform(sstartdate, 'dd/MM/YYYY') + " - " +datepipe.transform(senddate, 'dd/MM/YYYY') + '\n'
        }  
        
      });
    

    })
   }
  }
  updatevisitreset()
  {
    this.updatevisitdatesform.get("fvenddate").setValue('');
  }
  updatevisitdates() {
    this.messageService.clear();
    this.visitdatesisSubmitted = true;
    if (!this.updatevisitdatesform.valid) {
      return false;
    } else {
      if (this.dateLessThan(new Date(this.updatevisitdatesform.get("fvstartdate").value), new Date(this.updatevisitdatesform.get("fvenddate").value))) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End date should be greater than Start date' });
        return;
      } else {
        this.updateCalenderModal=true;
        var re = /00:00:00/;
        var startdate = this.updatevisitdatesform.get("fvstartdate").value;
        var stDate = startdate.toString().replace(re, "12:00:00");
        var enddate = this.updatevisitdatesform.get("fvenddate").value;
        var eDate = enddate.toString().replace(re, "23:59:00");
        const data = {
          startDate: environment.datePipe.transform(new Date(startdate), 'yyyy-MM-dd'),
          endDate: environment.datePipe.transform(new Date(enddate), 'yyyy-MM-dd'),
          updatedBy: this.loginusername,
          updatedDate: new Date(),
        }
        this.scheduleeventservice.updateserviceeventswithvdate(this.selectedVisitdata.eventId, data).subscribe(res => {
          this.updateCalenderModal = false;
          this.visitdatesisSubmitted = false;
          this.visitdatesModal = false;
          this.messageService.add({ severity: 'success', summary: 'success', detail: 'Updated visit dates for ' + this.selectedVisitdata.serviceId });
          this.ngOnInit();
          this.updatevisitdatesform = this.fb.group({
            fvstartdate: ['', [Validators.required]],
            fvenddate: ['', [Validators.required]],
          });
        }, err => {
          this.updateCalenderModal = false;
          this.visitdatesisSubmitted = false;
          this.visitdatesModal = false;
          this.messageService.add({ severity: 'error', summary: 'success', detail: 'Updated visit dates for ' + this.selectedVisitdata.serviceId });
          this.updatevisitdatesform = this.fb.group({
            fvstartdate: ['', [Validators.required]],
            fvenddate: ['', [Validators.required]],
          });
        });


      }
      
    }
  }
resetenddate()
  {
    this.addeventform.get("fenddate").setValue('');
  }
  onSubmit(){
    var re = /00:00:00/;
    var startdate = this.addeventform.get("fstartdate").value;
    var stDate = startdate.toString().replace(re, "00:01:00");
    var enddate = this.addeventform.get("fenddate").value;
    var eDate = enddate.toString().replace(re, "23:59:00");
    this.progressbar = true;
    if (this.addeventform.get("feventtype").value == 'L') {
      this.scheduleeventservice.overlappedFlexPeriod(stDate, eDate).subscribe(res => {
        if(!res){
          this.addEvent();
          return false;
        }

        if (this.todaydate > new Date(res.leaveCutoffDate) && res) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Leave cut-off date was ' + this.datepipe.transform(res.leaveCutoffDate, 'dd/MM/yyyy') + ',please contact your Hub Coordinator.' });
          this.progressbar = false;
          return;
        }else{
          this.addEvent();
        }
      })
    }else{
      this.addEvent();
    }
    
  }
    addEvent() {
      let flag=true;
    this.messageService.clear();
    this.isSubmitted = true;
    let daysofweek = "";

    if (!this.addeventform.valid) {
        this.progressbar = false;
        return false;
      } else {
        console.log("fdaysofweek"+this.addeventform.get("fdaysofweek").value)
        if(this.addeventform.get("feventtype").value=='LP' && (this.addeventform.get("fdaysofweek").value ==undefined || this.addeventform.get("fdaysofweek").value ==null || this.addeventform.get("fdaysofweek").value==""))
        {

          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please choose Days of the week' });
          this.progressbar = false;

          return;
        } 

        let userIdtocheck = this.selectedUserId;

         //check for clashing events
      console.log("check for clashing events"+this.selectedUserId);

      if(this.selectedUserId == null || this.selectedUserId== undefined)
      {
        userIdtocheck = this.userId;
      }

      this.scheduleeventservice.getUserEventsById(userIdtocheck).subscribe(res=>{

        let mypromise = new Promise((resolve,reject) => {
          let startDate = environment.datePipe.transform(new Date(this.addeventform.get("fstartdate").value), 'yyyy-MM-dd');
          let endDate = environment.datePipe.transform(new Date(this.addeventform.get("fenddate").value), 'yyyy-MM-dd');
          let fdaysofweek =this.addeventform.get("fdaysofweek").value;
          console.log("startDate and enddate"+startDate+endDate);
          //console.log("res"+res)

          if(res==null || res==undefined || res.length==0)
          {
            resolve(null);

          }
       
          let filterednonrecurring = res.filter(element=>(  (fdaysofweek==null || fdaysofweek==undefined || fdaysofweek=='') && (element.eventType!='MAT' && element.eventType!='O' && element.eventType!='LP' && element.eventType!='LWOP')&&(((startDate>=environment.datePipe.transform(element.startDate, 'yyyy-MM-dd') && startDate<=environment.datePipe.transform(element.endDate, 'yyyy-MM-dd')) || (endDate>=environment.datePipe.transform(element.startDate, 'yyyy-MM-dd') && endDate<=environment.datePipe.transform(element.endDate, 'yyyy-MM-dd')) || (startDate<environment.datePipe.transform(element.startDate, 'yyyy-MM-dd') && endDate>environment.datePipe.transform(element.endDate, 'yyyy-MM-dd'))))   ))
           console.log("filterednonrecurring..."+filterednonrecurring.length)
              if(filterednonrecurring!=null && filterednonrecurring.length>0)
              {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Clashing event' });
                this.addeventform.reset();
                this.isSubmitted=false;
  
  
                flag= false;
                resolve(null);
              }
            
  
              //check for recurring events 
              let filtered_recurring = res.filter(element=>((element.eventType=='MAT' || element.eventType=='O' || element.eventType=='LP' || element.eventType=='LWOP')&&  ((startDate>=environment.datePipe.transform(element.startDateRecur, 'yyyy-MM-dd') && startDate<=environment.datePipe.transform(element.endDateRecur, 'yyyy-MM-dd')) || (endDate>=environment.datePipe.transform(element.startDateRecur, 'yyyy-MM-dd') && endDate<=environment.datePipe.transform(element.endDateRecur, 'yyyy-MM-dd')) || (startDate<environment.datePipe.transform(element.startDateRecur, 'yyyy-MM-dd') && endDate>environment.datePipe.transform(element.endDateRecur, 'yyyy-MM-dd'))) ) )
              //input event could be both recurring and non recurring 
              console.log("filtered_recurring"+filtered_recurring.length)
              //            if input event is a nonrecurring event
             // console.log("this.addeventform.get('fdaysofweek').value"+this.addeventform.get('fdaysofweek').value)
             daysofweek="";
            if(filtered_recurring!=null && filtered_recurring.length>0 && (this.addeventform.get('fdaysofweek')==null||this.addeventform.get('fdaysofweek').value==null||this.addeventform.get('fdaysofweek').value==""))
           
              {
                
  
                
                this.confirmationService.confirm({
                  message: 'Are you sure you want add this event, it might be conflicting with other recurring events? ',
                  header: 'Confirm',
                  icon: 'pi pi-exclamation-triangle',
                  accept: () => {
                    resolve(null);
  
                    }
                  
                });  
                
                
  
                
                  
              }

              else{  // if input is a recurring event
                if( this.addeventform.get('fdaysofweek')!=null && this.addeventform.get('fdaysofweek').value!="" && this.addeventform.get('fdaysofweek').value!=null)
                {
                  let arraydaysofweek =  Array.from(this.addeventform.get('fdaysofweek').value).map(({ value }) => value);
                  console.log("in else arraydaysofweek"+arraydaysofweek)
  
                  // input is a recurring event
                  let filtered_recurring_inputrecurring = filtered_recurring.filter(e=> arraydaysofweek.some(r=>e.daysofweek.includes(r)))
                  console.log("in else filtered_recurring_inputrecurring"+arraydaysofweek)
    
                  if(filtered_recurring_inputrecurring!=null && filtered_recurring_inputrecurring.length>0)
                  {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Clashing event' });
                    this.addeventform.reset();
                    this.isSubmitted=false;
    
                    flag= false;
                    resolve(null);
                  }
                  else{ // no clashes
                    resolve(null);
    
                  }
                } else{ // no clashes
                  resolve(null);
  
                }
                
              }
              
  
              
      
      })
      mypromise.then(() => {
        if(flag)
        {
        var re = /00:00:00/;
        var startdate = this.addeventform.get("fstartdate").value;
        var stDate = startdate.toString().replace(re, "00:01:00");
        var enddate = this.addeventform.get("fenddate").value;
        var eDate = enddate.toString().replace(re, "23:59:00");
        if (this.dateLessThan(new Date(stDate), new Date(eDate))) {
          this.progressbar = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End date should be greater than Start date' });
        } else {
          this.sse = new scheduleevent;
          this.sse.userId = this.userId;
          if (this.addeventform.get("feventtype").value == 'L') {
            this.sse.title = 'Leave';
          } else if (this.addeventform.get("feventtype").value == 'F') {
            this.sse.title = 'Flex';
          }  else if (this.addeventform.get("feventtype").value=='LP'){
            this.sse.title = 'Leave - Part-time';
          }else {
            this.sse.title = 'Travel';
          }

          this.sse.eventType = this.addeventform.get("feventtype").value;

          this.sse.startDate = environment.datePipe.transform(new Date(startdate), 'yyyy-MM-dd');
          this.sse.endDate = environment.datePipe.transform(new Date(enddate), 'yyyy-MM-dd');
          this.sse.description = this.addeventform.get("fnotes").value;
          //this.sse.futureVisit = 'Y';
          this.sse.createdBy = this.loginusername;
          this.sse.createdDate = new Date();

          this.sse.description = this.addeventform.get("fnotes").value;
          let arr1 = ['1','2','3','4','5'];
         // console.log("daysofweek............"+daysofweek)
        
  
  
          if(this.addeventform.get("feventtype").value=='LP')
          {
            
            var values = Array.from(this.addeventform.get('fdaysofweek').value).map(({ value }) => value);
            console.log(values);
            
            this.sse.startDateRecur = environment.datePipe.transform(new Date(this.addeventform.get("fstartdate").value), 'yyyy-MM-dd'); 
            this.sse.endDateRecur =environment.datePipe.transform(new Date(this.addeventform.get("fenddate").value), 'yyyy-MM-dd');
            this.sse.daysofweek= values.toString(); 
          }

          // call service
          this.scheduleeventservice.create(this.sse).subscribe(res => {
            this.messageService.add({ severity: 'success', summary: 'success', detail: 'Event added.' });
            this.progressbar = false;

            this.ngOnInit();
          }, err => {
            this.messageService.add({ severity: 'error', summary: 'failed', detail: 'Event failed' });
            this.progressbar = false;

          });
          this.isSubmitted = false;
          this.progressbar = false;
          this.addeventform.reset();
        }
      }
      else{
        this.progressbar = false;

      }
    })
  })
}

  }
  getbgcolor(eventType: any) {
    /*
      leave and Flex #F03030
      Training and Conference - #3361FF
      Team meeting and Admin Day - #A569BD
      Report Writing and Desktop Audit - #ABEBC6
      Compliance and Investigation - #FCE32E
      Pre,Post approval and change of ownership - #C10AC3
      Travel - #F06630 
      Assessment and visit -  #1AA11A
      Secondment Visit - #EBEEEE
      Multiple -#9B9E9C
      others - #26C8F3
      School holidays watermark colorMultiple events - #D6F9F1
    */
    var bgcolor = '';
    if (eventType == 'L' || eventType == 'F') {
      bgcolor = '#F03030';
    } else if (eventType == 'T' || eventType == 'CF') {
      bgcolor = '#6699CC';
    } else if (eventType == 'M' || eventType == 'A') {
      bgcolor = '#A569BD';
    } else if (eventType == 'D' || eventType == 'R') {
      bgcolor = '#ABEBC6';
    } else if (eventType == 'COM' || eventType == 'I' ||  eventType == 'MAT') {
      bgcolor = '#FCE32E';
    } else if (eventType == 'PA' || eventType == 'PR' || eventType == 'CofO') {
      bgcolor = '#C10AC3';
    } else if (eventType == 'H') {
      bgcolor = '#F06630 ';
    } else if (eventType == 'V') {
      bgcolor = '#1AA11A';
    } else if (eventType == 'ME') {
      bgcolor = '#9B9E9C';
    } else if (eventType == 'O') {
      bgcolor = '#26C8F3';
    } else if (eventType == 'SV') {
      bgcolor = '#EBEEEE';
    } else if (eventType == 'W') {
      bgcolor = '#F7F9FB';
    } else if (eventType == 'termHoliday') {
      bgcolor = '#D3D3D3';
    } else if (eventType == 'publicHoliday') {
      bgcolor = '#ffcccb';
    }else if (eventType == 'LP') {
      bgcolor = '#F4C2C2';
    }else if (eventType == 'LWOP') {
      bgcolor = '#de7c7c';
    } else {
      bgcolor = '';
    }

    return bgcolor;
  }


  get feventtype() {
    return this.addeventform.get('feventtype');
  }
  get fstartdate() {
    return this.addeventform.get('fstartdate');
  }
  get fenddate() {
    return this.addeventform.get('fenddate');
  }
  get fdaysofweek() {
    return this.addeventform.get('fdaysofweek');
  }
  get fvstartdate() {
    return this.updatevisitdatesform.get('fvstartdate');
  }
  get fvenddate() {
    return this.updatevisitdatesform.get('fvenddate');
  }
}
